.hidden {
    display: none;
}
.side-panel {
    p {
        margin-bottom: 0;
    }
}

.side-panel__section {
    margin-bottom: 24px;
}